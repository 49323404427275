<template>
  <section-description-text
    :description="documentToHtmlString(translations.certificatesCertificates)"
  />
</template>

<script>
  import SectionDescriptionText from '@/views/sections/reusable/DescriptionText'
  import { mapState } from 'vuex'
  import { STORE_NAMESPACE_CERTIFICATES } from '@/views/certificates/Index.vue'
  import { documentToHtmlString } from '@contentful/rich-text-html-renderer'

  export default {
    name: 'SectionCertificatesDescription',
    components: {
      SectionDescriptionText,
    },
    computed: {
      ...mapState(STORE_NAMESPACE_CERTIFICATES, [
        'translations',
      ]),
    },
    methods: {
      documentToHtmlString,
    },
  }
</script>
